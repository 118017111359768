<template>
  <v-app>
    <div>
      <!-- Header Alert -->
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text">
          Daftar <b>Rekap Tindakan Medis Perbulan</b> {{ clinic_name }}
        </div>
        <!-- <b-button
        squared
        variant="success"
        @click="$router.push('/medicine-packages/add')"
        v-if="btn"
      >Tambah</b-button> -->
      </b-alert>

      <!-- Component List -->
      <div class="row">
        <div class="col-md-12">
          <Card>
            <template v-slot:body>
              <div class="row">
                <div class="col-md-4 py-1">
                  <span class="d-none d-sm-block">
                    <b-input-group>
                      <template #prepend>
                        <b-button squared @click="resetFilter" variant="danger"
                          >Reset</b-button
                        >
                        <b-button
                          squared
                          @click="generalFilter"
                          variant="success"
                          >Cari</b-button
                        >
                      </template>

                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                        class=""
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <b-form-input
                            v-model="date"
                            type="text"
                            placeholder="Pilih Bulan"
                            autocomplete="off"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></b-form-input>
                        </template>
                        <v-date-picker v-model="date" scrollable type="month">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="chooseMonth(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </b-input-group>
                  </span>
                </div>
                <div class="col-md-12">
                  <b-tabs>
                    <b-tab title="Perawat" active>
                      <TableNurse
                        :month="month"
                        :year="year"
                        :monthName="monthName"
                      />
                    </b-tab>
                    <b-tab title="Dokter">
                      <TableDoctor :month="month" :year="year" :monthName="monthName"/>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import TableNurse from "@/component/action-reports/Table.vue";
import TableDoctor from "@/component/action-reports/TableDoctor.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";

export default {
  name: "List",

  components: {
    Card,
    TableNurse,
    TableDoctor,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "action_name",
          label: "Nama Tindakan",
          sortable: true,
        },
        {
          key: "medicines",
          label: "Obat dan Alkes",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // user access
      btn: false,
      // Filter
      date: "",
      menu: false,
      modal: false,
      month: "",
      year: "",
      monthName: "",
      // other
      clinic_name:
        window.configuration == null
          ? "Medhop"
          : window.configuration.clinic_name,
    };
  },

  methods: {
    generalFilter() {
      this.$root.$emit(
        "paginateNurseRecapt",
        this.month,
        this.year,
        this.monthName
      );
      this.$root.$emit(
        "paginateDoctorRecapt",
        this.month,
        this.year,
        this.monthName
      );
    },
    resetFilter() {
      month = moment().format("MM");
      year = moment().format("YYYY");

      this.$root.$emit("paginateNurseRecapt", this.month, this.year);
    },

    chooseMonth(date) {
      // get month name
      let dates = date.split("-");
      this.month = dates[1];
      this.year = dates[0];

      if (this.month == "01") {
        this.monthName = "Januari";
      } else if (this.month == "02") {
        this.monthName = "Februari";
      } else if (this.month == "03") {
        this.monthName = "Maret";
      } else if (this.month == "04") {
        this.monthName = "April";
      } else if (this.month == "05") {
        this.monthName = "Mei";
      } else if (this.month == "06") {
        this.monthName = "Juni";
      } else if (this.month == "07") {
        this.monthName = "Juli";
      } else if (this.month == "08") {
        this.monthName = "Agustus";
      } else if (this.month == "09") {
        this.monthName = "September";
      } else if (this.month == "10") {
        this.monthName = "Oktober";
      } else if (this.month == "11") {
        this.monthName = "November";
      } else if (this.month == "12") {
        this.monthName = "Desember";
      }

      this.$refs.dialog.save(date);
    },
  },

  mounted() {
    // date
    let dates = moment().format("YYYY-MM");
    dates = dates.split("-");

    this.month = dates[1];
    this.year = dates[0];

    if (this.month == "01") {
      this.monthName = "Januari";
    } else if (this.month == "02") {
      this.monthName = "Februari";
    } else if (this.month == "03") {
      this.monthName = "Maret";
    } else if (this.month == "04") {
      this.monthName = "April";
    } else if (this.month == "05") {
      this.monthName = "Mei";
    } else if (this.month == "06") {
      this.monthName = "Juni";
    } else if (this.month == "07") {
      this.monthName = "Juli";
    } else if (this.month == "08") {
      this.monthName = "Agustus";
    } else if (this.month == "09") {
      this.monthName = "September";
    } else if (this.month == "10") {
      this.monthName = "Oktober";
    } else if (this.month == "11") {
      this.monthName = "November";
    } else if (this.month == "12") {
      this.monthName = "Desember";
    }


    this.$root.$emit(
        "paginateNurseRecapt",
        this.month,
        this.year,
        this.monthName
      );
      this.$root.$emit(
        "paginateDoctorRecapt",
        this.month,
        this.year,
        this.monthName
      );

    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Rekap Tindakan Medis Perbulan" },
    ]);
  },
};
</script>
